@font-face {
  font-family: 'Poppins';
  src: url('../src/font/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../src/font/Poppins-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url('../src/font/Poppins-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../src/font/Poppins-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url('../src/font/Poppins-Thin.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../src/font/Poppins-ThinItalic.ttf') format('truetype'); 
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url('../src/font/Poppins-Light.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../src/font/Poppins-LightItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url('../src/font/Poppins-Medium.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../src/font/Poppins-MediumItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}


body {
  margin: 0;
  font-family: 'Poppins','Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
