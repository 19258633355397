:root {
  --colorprimary: #ca473b;
  --colorprimarydark: #a03c33;
  --colorprimarylight: #b1993c;
  --colorgreen: #5A777B;
  --colorbluelight: #607fe4;
  --colorbluelight-a50: #607fe482;


  --colorwhite: #ffffff;
  --colorblack: #000000;
  --colorgrayalpha: rgba(0, 0, 0, 0.5);
  --colorgrayalpha90: rgba(0, 0, 0, 0.9);


  --colorgray: rgb(245, 245, 245);
  --colorgraydark: #dfdfdf;
  --colorgraybox: rgb(232, 232, 232);
  --colorred: #b60c0c;

  --colorgray-a50: rgba(245, 245, 245, 0.5);
}

.full-height {
  min-height: calc(100vh - 90px);
}

.blur {
  /* Add the blur effect */
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

input,
textarea {
  outline: 0px;
  border: 0;
  margin: 0;
  font-family: "Poppins", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 15px;
  width: 100%;
  resize: none;
  -webkit-appearance: none;
}

select {
  outline: 0px;
  border: 0;
  margin: 0;
  font-family: "Poppins", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 15px;
	-webkit-appearance: none;
}
